import { useSelector } from 'react-redux';

import { LString, t } from '@web-solutions/module-localization';
import { LocalizedString } from '@web-solutions/core/constants/remote-config';
import { ButtonConfig } from '@web-solutions/core/payment/constants';

import { RootState }  from '../../../../apps/brainary-default/src/store';

export const getButtonTitleByConfig = (config: ButtonConfig, defaultTKeyText: string, details?: object) => {
  let { uppercaseTitle, title } = (config || {});
  const locale: keyof LocalizedString = useSelector((state: RootState) => state.locale.locale);

  if (title && typeof title === 'string' && title[0] === '_') {
    return t(title);
  }

  if(title) {
    const upgradeTitle = title[locale as keyof LString] as string

    return uppercaseTitle ? upgradeTitle.toUpperCase() : upgradeTitle;
  }
};
