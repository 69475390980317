export const ELEMENTS_WITHOUT_LOCALIZATION = {
  singIn: 'Sign in with email',
};

export const SUPPORTED_LANGUAGES = {
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  pt: 'Portuguese',
  de: 'German',
  tr: 'Turkish',
  it: 'Italian',
  cs: 'Czech',
  ro: 'Romanian',
  pl: 'Polish',
  id: 'Indonesian',
} as const;
