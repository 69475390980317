import { SUPPORTED_LANGUAGES } from './constants';

export type SupportedLanguage = keyof typeof SUPPORTED_LANGUAGES;

const getLocale = () => {
  let locale = 'en';

  const urlParams = new URLSearchParams(window.location.search);
  const urlLang = urlParams.get('lang') as SupportedLanguage;
  const browserLang = navigator.language.slice(0, 2) as SupportedLanguage;

  if (urlLang && SUPPORTED_LANGUAGES[urlLang]) {
    locale = urlLang;
  } else if (SUPPORTED_LANGUAGES[browserLang]) {
    locale = browserLang;
  }

  return locale;
};

export default getLocale;
