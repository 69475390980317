import {
  BASE_QUIZ,
  type BaseQuizRoute,
  SUBSCRIPTION as BASE_SUBSCRIPTION,
} from '@web-solutions/base-app/constants/routes';

import { UPSALE as BASE_UPSALE } from 'src/screens/upsale/store/types';

import type { AfterPurchaseRoutes } from './screens';

export const LOCAL_QUIZ = {
  WELCOME: 'WELCOME',
  MAGIC: 'MAGIC',
  GENDER: 'GENDER',
} as const;

export const QUIZ = {
  ...BASE_QUIZ,
  ...LOCAL_QUIZ,
} as const;

export const UPSALE = {
  ...BASE_UPSALE,
} as const;

type QuizNameKeys = keyof typeof LOCAL_QUIZ;
export type QuizRoute = (typeof LOCAL_QUIZ)[QuizNameKeys] | BaseQuizRoute;

export const SUBSCRIPTION = { ...BASE_SUBSCRIPTION };

export const SCREENS_WITHOUT_PROGRESSBAR = [
  SUBSCRIPTION.MAIN,
  SUBSCRIPTION.SUCCESS,
  BASE_QUIZ.EMAIL,
  BASE_QUIZ.MAGIC,
];

export const MULTI_VARIANCE_SCREENS: string[] = [];

export const ROUTES = null;

export const AFTER_PURCHASE_ROUTES: AfterPurchaseRoutes = [];
