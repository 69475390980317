'use client'

import { UPSALE } from "brainary-default/src/constants/routes"
import { useRef } from 'react'
import type { FC, ComponentProps, PropsWithChildren } from 'react';
import classNames from 'classnames';

import { useLocation } from "react-router-dom"

import type { ThemeMode } from '@web-solutions/core/interfaces/theme';

import { ReactComponent as IconBurger } from './icons/burger.svg';
import { ReactComponent as IconClose } from './icons/close.svg';

import classes from './style.module.scss';

interface SideBarProps extends PropsWithChildren<ComponentProps<'div'>> {
  visible: boolean,
  onVisibilityChange: () => void,
  theme?: ThemeMode,
}

export const SideBar: FC<SideBarProps> = ({
  theme = 'light',
  visible,
  onVisibilityChange,
  className,
  children
}) => {
  const r = useRef(null);

  const location = useLocation();

  const isUpsalePage = location?.pathname === UPSALE.MAIN;

  const handleBackDropClick = (e: React.SyntheticEvent) => {
    if (e.target === r.current) {
      onVisibilityChange();
    }
  }

  const classWrapper = classNames(classes.wrapper, className, {
    [classes.show]: visible,
    [classes.dark]: theme === 'dark',
    [classes.dark_green]: theme === 'default',
  })

  const classOpenWrapper = classNames(classes.open, {}, {
    [classes.upsale]: isUpsalePage
  })

  return (
    <div className={classWrapper} ref={r} onClick={handleBackDropClick}>
      <div className={classes.icon}>
        <IconBurger className={classOpenWrapper} onClick={onVisibilityChange} />
        <div className={classes.menu}>
          <IconClose className={classes.close} onClick={onVisibilityChange} />
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  )
};
