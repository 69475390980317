import { StrictMode, FC, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

import Analytics from '@web-solutions/module-analytics';

import { ThemeContextProvider } from 'core/hooks/use-theme-context';
import { Theme } from 'core/constants/theme';
import { CookieClear } from 'core/containers/cookie-clear';

import { initLocalization } from 'src/localization';
import { getMagnusToken } from 'src/store/app/utils';

import packageInfo from '../package.json';

import configureStore from './store';
import getLocale from './localization/get-locale';
import { useAppSelector } from './store/hooks';

import 'react-toastify/dist/ReactToastify.min.css';
import 'src/styles/global.scss';
import 'src/styles/index.scss';
import 'src/styles/variables.css';

interface AppProps {
  AppRouter: FC;
}
const { store, persistor } = configureStore();
const App: FC<AppProps> = ({ AppRouter }) => {
  const pType = window.location.pathname.split('/')[1];
  // const { store, persistor } = configureStore();

  Analytics.init(getMagnusToken(), packageInfo.version);
  const p = new URLSearchParams(window.location.search);

  const variant = p.get('variant');
  if (variant) {
    Analytics.setUserProperty('variant', variant);
  }
  if (pType !== 'terminate') {
    (async () => {
      const { load } = await import('./store/app/actions');
      store.dispatch(load());
    })();
  }

  initLocalization();

  const localizationEnabled = useAppSelector(
    (state: any) => state.remoteConfig.localizationEnabled
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const checkLocalization = async () => {
      const localizationEnabled = store.getState().remoteConfig.localizationEnabled;

      if (localizationEnabled) {
        const locale = getLocale();
        const { setLocale } = await import('./localization/store');
        dispatch(setLocale(locale));
      }
    };

    checkLocalization();
  }, [localizationEnabled]);

  return (
    <CookieClear>
      <ThemeContextProvider value={{ theme: Theme.light }}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRouter />
          <ToastContainer position='bottom-center' autoClose={5000} closeOnClick />
        </PersistGate>
      </ThemeContextProvider>
    </CookieClear>
  );
};

export const render = (AppRouter: FC) => {
  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App AppRouter={AppRouter} />
        </PersistGate>
      </Provider>
    </StrictMode>
  );
};
