import axios from 'axios';
import { ThunkAction } from 'redux-thunk';

import Analytics from '@web-solutions/module-analytics';

import { RootState } from 'src/store/types';

export const submitCancelSubscription = (recurlyId: string): ThunkAction<Promise<void>, RootState, unknown, any> => {
  return async (dispatch, getState) => {
    const apiAuth = getState().profile.apiAuth;
    const email = getState().profile.email;

    try {
      const profileResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/payments/subscriptions/`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${apiAuth.token_type} ${apiAuth.access_token}`,
          },
        }
      );
      const subscriptions = profileResponse.data?.data;

      const subscriptionToCancel = subscriptions.find(
        (subscription: any) => subscription.product !== recurlyId && subscription.customer_email === email
      );
      if (subscriptionToCancel) {
        await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/payments/subscriptions/cancel?&email=${email}&sig=${subscriptionToCancel.sig}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${apiAuth.token_type} ${apiAuth.access_token}`,
            },
          }
        );
        Analytics.trackEvent('subscription', 'cancel');
      }
    } catch (error) {
      console.error('Error canceling the subscription:', error);
      throw error;
    }
  };
};
