import { createSlice } from '@reduxjs/toolkit';

import { UPSALE } from './types';

export interface UpsaleState {
  currentStep: number;
  steps: string[];
}

const initialState: UpsaleState = {
  currentStep: 0,
  steps: [
    UPSALE.SKIP_TRIAL,
    UPSALE.ADD_PACKS,
    UPSALE.EARLY_ACCESS,
    UPSALE.DETAILED_REPORT,
    UPSALE.OFFLINE_MODE,
  ],
};

const upsaleSlice = createSlice({
  name: 'upsale',
  initialState,
  reducers: {
    nextStep: (state) => {
      state.currentStep = Math.min(state.currentStep + 1, state.steps.length);
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
  },
});

export const { nextStep, setCurrentStep } = upsaleSlice.actions;

export default upsaleSlice;
