import { getMagnusToken } from '../../../store/app/utils';

import { HEADERS, TRANSACTION_URL } from './constants';
import instance from './instance';

import {  Purchase, RecordTransactionRequest } from './types';

export const recordTransaction = async (
  chargeInvoice: any,
  idfm: string,
  purchase: Purchase
): Promise<void> => {
  const requestBody: RecordTransactionRequest = {
    transaction: {
      order_id: chargeInvoice.id,
      payment_service: 'recurly',
      product_id: chargeInvoice.lineItems[0]?.itemCode,
      is_one_time_payment: true,
    },
    token: getMagnusToken(),
    idfm,
    internal_uid: purchase.email || '',
  };

  await instance.post(TRANSACTION_URL, requestBody, {
    headers: HEADERS,
  });
};
