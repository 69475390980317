import { LocalizedString } from 'core/constants/remote-config';

export enum UPSALE {
  MAIN = '/upsale',
  SKIP_TRIAL = '/upsale/screens/skip-trial',
  ADD_PACKS = '/upsale/screens/add-packs',
  EARLY_ACCESS = '/upsale/screens/early_access',
  DETAILED_REPORT = '/upsale/screens/detailed-report',
  OFFLINE_MODE = '/upsale/screens/offline-mode',
}

export type ListItem = {
  icon: string;
  text: LocalizedString;
};

export type SuccessMessageType = {
  emoji: string;
  title: LocalizedString;
  message: LocalizedString;
};
