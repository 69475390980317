import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectedAnswers {
  answerId: string;
  isCorrect: boolean;
  counted: boolean;
}

export interface QuizState {
  selectedAnswers: Record<string, SelectedAnswers>;
  correctAnswersCount: number;
}

const initialState: QuizState = {
  selectedAnswers: {},
  correctAnswersCount: 0,
};

const slice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    setSelectedAnswers: (
      state,
      action: PayloadAction<{
        questionId: string;
        answerId: string;
        isCorrect: boolean;
      }>,
    ) => {
      const { questionId, answerId, isCorrect } = action.payload;

      if (state.selectedAnswers[questionId]) {
        state.selectedAnswers[questionId].answerId = answerId;

        if (isCorrect && !state.selectedAnswers[questionId].counted) {
          state.correctAnswersCount += 1;
          state.selectedAnswers[questionId].counted = true;
        }
      } else {
        state.selectedAnswers[questionId] = {
          answerId,
          isCorrect,
          counted: isCorrect,
        };

        if (isCorrect) {
          state.correctAnswersCount += 1;
        }
      }
    },
  },
});

export { slice as quizSlice };
